
export default {
    data() {
        return {
            // elmentList:
            //     [
            //         {
            //             pcImg: require('./images/pc/banner1.jpg'),
            //             phoneImg: require('./images/mobile/banner1.jpg'),
            //             descList: [
            //                 {
            //                     descName: '防爆化工轮式巡检机器人',
            //                     sort: 1
            //                 }, {
            //                     descName: 'Ex d IIB T4 Gb',
            //                     sort: 2
            //                 }, {
            //                     descName: 'IP67',
            //                     sort: 3
            //                 }, {
            //                     descName: '≥6h',
            //                     sort: 4
            //                 }, {
            //                     descName: '1.5m/s',
            //                     sort: 5
            //                 },
            //                 {
            //                     descName: '防爆等级',
            //                     sort: 6
            //                 }, {
            //                     descName: '防护等级',
            //                     sort: 7
            //                 }, {
            //                     descName: '续航时间',
            //                     sort: 8
            //                 }, {
            //                     descName: '行驶速度',
            //                     sort: 9
            //                 }],
            //              type: 'image',
            //             sort: 1
            //         },
            //         {
            //             pcImg: require('./images/pc/banner2.jpg'),
            //             phoneImg: require('./images/mobile/banner2.jpg'),
            //             descList: [
            //                 {
            //                     descName: '防爆化工轮式巡检机器人',
            //                     sort: 1
            //                 }, {
            //                     descName: '搭载导航设备和感知设备，可全自主或半自主方式替代或辅助人工进行巡检，可对现场环境数据进行实时采集与智能分析，实现设备缺陷自动诊断、环境设施智能运维、生产安全防护、隐患排查等功能，大幅度降低一线高危作业人员的危险指数和劳动强度，全面提升企业数字化运维能力。',
            //                     sort: 2
            //                 },],
            //              type: 'image',
            //             sort: 2
            //         },
            //         {
            //             pcImg: require('./images/pc/banner3.jpg'),
            //             phoneImg: require('./images/mobile/banner3.jpg'),
            //             descList: [
            //                 {
            //                     descName: '应用场景',
            //                     sort: 1
            //                 }, {
            //                     descName: '防爆化工轮式巡检机器人可应用于石油、化工、天然气、电力等行业、通用于各类油、气、田、管、罐等易燃易爆、有毒有害的采集站、化工厂或存储区的智能巡检业务场景。',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 3
            //         },
            //         {
            //             pcImg: require('./images/pc/banner4.jpg'),
            //             phoneImg: require('./images/mobile/banner4.jpg'),
            //             descList: [
            //                 {
            //                     descName: '表计识别读数',
            //                     sort: 1
            //                 }, {
            //                     descName: '通过AI视觉技术读取各类仪器仪表数据，采集到的数据超过阈值则产生预警。',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 4
            //         },
            //         {
            //             pcImg: require('./images/pc/banner5.jpg'),
            //             phoneImg: require('./images/mobile/banner5.jpg'),
            //             descList: [
            //                 {
            //                     descName: '跑冒滴漏监测',
            //                     sort: 1
            //                 }, {
            //                     descName: '通过AI视觉识别技术等手段进行跑冒滴漏检测，能够显示泄漏位置的坐标并预警。',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 5
            //         },
            //         {
            //             pcImg: require('./images/pc/banner6.jpg'),
            //             phoneImg: require('./images/mobile/banner6.jpg'),
            //             descList: [
            //                 {
            //                     descName: '无人自主巡检',
            //                     sort: 1
            //                 }, {
            //                     descName: '无需人工干预，可实现自动巡检、手动巡检、异常巡检方式，沿着轨道自动巡检。',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 6
            //         },
            //         {
            //             pcImg: require('./images/pc/banner7.jpg'),
            //             phoneImg: require('./images/mobile/banner7.jpg'),
            //             descList: [
            //                 {
            //                     descName: '智能避障功能',
            //                     sort: 1
            //                 }, {
            //                     descName: '搭载避障传感器，通过激光雷达、防碰撞传感器实现安全运行、避障及预警。',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 7
            //         },
            //         {
            //             pcImg: require('./images/pc/banner8.jpg'),
            //             phoneImg: require('./images/mobile/banner8.jpg'),
            //             descList: [
            //                 {
            //                     descName: '设备红外测温',
            //                     sort: 1
            //                 }, {
            //                     descName: '搭载红外热成像仪，能够对设备表面温度进行采集，对设备温度异常进行预警。',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 8
            //         },
            //         {
            //             pcImg: require('./images/pc/banner9.jpg'),
            //             phoneImg: require('./images/mobile/banner9.jpg'),
            //             descList: [
            //                 {
            //                     descName: '环境气体监测',
            //                     sort: 1
            //                 }, {
            //                     descName: '可对氧气、一氧化碳、可燃性气体、硫化氢等易燃易爆、有毒有害气体进行实时监测。',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 9
            //         },
            //         {
            //             pcImg: require('./images/pc/banner10.jpg'),
            //             phoneImg: require('./images/mobile/banner10.jpg'),
            //             descList: [
            //                 {
            //                     descName: '防爆化工轮式巡检机器人',
            //                     sort: 0
            //                 },
            //                 {
            //                     descName: '规格参数',
            //                     sort: 1
            //                 },  {
            //                     descName: '外形尺寸(长×宽×高)',
            //                     sort: 2
            //                 },
            //                 {
            //                     descName: '防爆等级',
            //                     sort: 3
            //                 },
            //                 {
            //                     descName: '行走速度',
            //                     sort: 4
            //                 },
            //                 {
            //                     descName: '越障能力',
            //                     sort: 5
            //                 },
            //                 {
            //                     descName: '充电时间',
            //                     sort: 6
            //                 },
            //                 {
            //                     descName: '主导航方式',
            //                     sort: 7
            //                 },
            //                 {
            //                     descName: '整备质量',
            //                     sort: 8
            //                 },
            //                 {
            //                     descName: '防护等级',
            //                     sort: 9
            //                 }, {
            //                     descName: '爬坡能力',
            //                     sort: 10
            //                 },
            //                 {
            //                     descName: '涉水深度',
            //                     sort: 11
            //                 },
            //                 {
            //                     descName: '续航时间',
            //                     sort: 12
            //                 },
            //                 {
            //                     descName: '重复导航定位精度',
            //                     sort: 13
            //                 }, {
            //                     descName: '≤1420mm×820mm×1055mm',
            //                     sort: 14
            //                 },
            //                 {
            //                     descName: 'Ex d IIB T4Gb -Ex d IIC T6 Gb',
            //                     sort: 15
            //                 },
            //                 {
            //                     descName: '0-1.5m/s，速度可调',
            //                     sort: 16
            //                 },
            //                 {
            //                     descName: '120mm',
            //                     sort: 17
            //                 },
            //                 {
            //                     descName: '2小时充电量大于80%(快充)',
            //                     sort: 18
            //                 },
            //                 {
            //                     descName: 'SLAM激光导航和SLAM激光定位',
            //                     sort: 19
            //                 },
            //                 {
            //                     descName: '350kg',
            //                     sort: 20
            //                 },
            //                 {
            //                     descName: 'IP67',
            //                     sort: 21
            //                 }, {
            //                     descName: '≤25°',
            //                     sort: 22
            //                 },
            //                 {
            //                     descName: '200mm',
            //                     sort: 23
            //                 },
            //                 {
            //                     descName: '行驶速度保持在1m/s时不小于6h,待机不小于24h',
            //                     sort: 24
            //                 },
            //                 {
            //                     descName: '≤±2cm',
            //                     sort: 25
            //                 }],
            //              type: 'image',
            //             sort: 10
            //         },
            //         {
            //             pcImg: require('./images/pc/banner11.jpg'),
            //             phoneImg: require('./images/mobile/banner11.jpg'),
            //             descList: [
            //                 {
            //                     descName: '防爆化工轮式巡检机器人',
            //                     sort: 1
            //                 }, {
            //                     descName: '降低危险指数',
            //                     sort: 2
            //                 }, {
            //                     descName: '提升企业数字化运维能力',
            //                     sort: 3
            //                 }],
            //              type: 'image',
            //             sort: 11
            //         }
            //     ],
            elmentList:[]
        }
    },
    created() {
        this.$store.dispatch("pageset/getbannerIndex", 0);
        document.documentElement.style.setProperty('--var-primary-color', '#fff');
        // 获取当前页面的配置信息
        this.$store.dispatch('pageset/getPageInfo', this.$route.query.pageId).then(() => {
            this.elmentList = this.$store.state.pageset.sourceData.elmentList;
        });
    },
    methods: {
        retention() {
            this.$router.push('/reservation');
        },
        openPdf() {
            this.$router.push('/pdfView')
        },
    }
}